import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography, Grid, Button, CardContent, CircularProgress, Card } from "@material-ui/core";
import PropTypes from "prop-types";
import Page from "../../../components/Page";
import FormInput from "./FormInput";
import axios from "../../../axios";
import Snackbar from "../../../components/Snackbar";
import validate from "validate.js";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	inputContent: {
		marginTop: theme.spacing(1.5),
	},
	button: {
		marginTop: theme.spacing(3),
	},
	formGroup: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(3),
	},
	cardContent: {
		paddingTop: theme.spacing(1),
	},
}));

let schema = {
	password: {
		presence: { allowEmpty: false, message: "is required" },
	},
	confirmpassword: {
		presence: { allowEmpty: false, message: "is required" },
		equality: "password",
	},
};

const initialState = {
	isValid: false,
	values: {},
	touched: {},
	errors: {},
};

const UserUpdatePage = ({ match }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const { userid } = match.params;
	const [isUpdate, setIsUpdate] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [formState, setFormState] = useState(initialState);
	const [user, setUser] = useState({ name: "", initials: "" });

	useEffect(() => {
		// if id isset then we want to fetch the user from the api and fill the text fields
		if (userid) {
			setIsUpdate(true);
			axios
				.get(`/api/user/users/${userid}`)
				.then((response) => {
					const user = response.data.user;

					console.log(user);

					setUser(user);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [userid]);

	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	const handleCheckboxChange = (event) => {
		event.persist();

		const {
			target: { value },
		} = event;

		const permissions = typeof value === "string" ? value.split(",") : value;

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]: permissions,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	// Check if input has error
	const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));
	}, [formState.values, setFormState]);

	const handleSnackbarClick = (message, severity) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	const onUpdatePassword = async () => {
		let body = formState.values;
		setLoading(true);

		console.log(body);

		delete body.confirmpassword;

		console.log(body);

		try {
			if (isUpdate) {
				const updateUser = await axios.put("/api/user/updatepassword/" + userid, body);

				console.log(updateUser);

				if (updateUser.status === 200) {
					handleSnackbarClick(updateUser.data.message, "success");
				}
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 422) {
					const validationErrors = error.response.data.errors.map((error) => {
						var errors = "";
						for (var key in error) {
							errors += error[key] + "\n";
						}
						return errors;
					});
					handleSnackbarClick(validationErrors, "error");
				} else if (error.response.status === 409) {
					if (error.response.status) handleSnackbarClick("Kunne ikke opdatere kodeord", "error");
				} else if (error.response.status === 400 && error.response.data.errors) {
					if (error.response.data.errors[0].message) {
						const validationErrors = error.response.data.errors.map((error) => {
							return error.message;
						});
						handleSnackbarClick(validationErrors, "error");
					} else {
						handleSnackbarClick(error.response.data.errors, "error");
					}
				} else {
					console.log("There was an error updating users password");
					console.log(error);
					handleSnackbarClick("Kunne ikke opdatere bruger", "error");
				}
			} else {
				console.log("There was an error updating users password");
				console.log(error);
				handleSnackbarClick("Kunne ikke opdatere bruger", "error");
			}
		}
		setLoading(false);
	};

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
					<Grid item>
						<Typography component="h2" variant="overline">
							Bruger
						</Typography>
						<Typography component="h1" variant="h3">
							Opdater kodeord {user.name}
						</Typography>
					</Grid>
				</Grid>
				<FormInput
					hasError={hasError}
					formState={formState}
					handleChange={handleChange}
					handleCheckboxChange={handleCheckboxChange}
					className={classes.inputContent}
				/>
				{/* <CreateButton isUpdate={isUpdate} formDisabled={!formState.isValid} loading={loading} onClick={onUpdatePassword} className={classes.button} /> */}
				<Card className={clsx(classes.button)}>
					<CardContent className={classes.cardContent}>
						<form>
							<Grid container spacing={10}>
								<Grid item xs>
									<div className={classes.formGroup} align="center">
										{loading ? (
											<CircularProgress />
										) : (
											<Button disabled={!formState.isValid} onClick={onUpdatePassword} variant="contained" color="primary">
												Opdater kodeord
											</Button>
										)}
									</div>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>
			</Container>
			{snackbarMessage && snackbarOpen && snackbarSeverity ? (
				<Snackbar open={snackbarOpen} autoHideDuration={6000} message={snackbarMessage} onClose={handleSnackbarClose} severity={snackbarSeverity} />
			) : null}
		</Page>
	);
};

UserUpdatePage.propTypes = {
	match: PropTypes.object.isRequired,
};

export default UserUpdatePage;

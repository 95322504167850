/* eslint-disable react/no-multi-comp */
import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Drawer, List, Hidden } from "@material-ui/core";
import NavItem from "../../components/NavItem";
import navConfig from "./navConfig";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	mobileDrawer: {
		width: 256,
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: "calc(100% - 64px)",
	},
	navigation: {
		overflow: "auto",
		padding: theme.spacing(0, 2, 2, 2),
		flexGrow: 1,
	},
}));

function renderNavItems({
	// eslint-disable-next-line react/prop-types
	items,
	subheader,
	key,
	permission,
	...rest
}) {
	return (
		<List key={key}>
			{/* {subheader && (
				<ListSubheader key={1} disableSticky>
					{subheader}
				</ListSubheader>
			)} */}
			{/* eslint-disable-next-line react/prop-types */}
			{items.reduce(
				// eslint-disable-next-line no-use-before-define
				(acc, item) => reduceChildRoutes({ acc, item, ...rest, permission }),
				[]
			)}
		</List>
	);
}

function reduceChildRoutes({ acc, pathname, item, permission, depth = 0 }) {
	if (item.items) {
		if (permission) {
			if (item.permission.some((p) => permission.split(",").includes(p))) {
				const open = matchPath(pathname, {
					path: item.href,
					exact: false,
				});

				acc.push(
					<NavItem depth={depth} icon={item.icon} key={item.href} label={item.label} open={Boolean(open)} title={item.title}>
						{renderNavItems({
							depth: depth + 1,
							pathname,
							items: item.items,
							permission,
						})}
					</NavItem>
				);
			}
		}

		// if (item.permission.includes(permission)) {
		// 	const open = matchPath(pathname, {
		// 		path: item.href,
		// 		exact: false,
		// 	});

		// 	acc.push(
		// 		<NavItem depth={depth} icon={item.icon} key={item.href} label={item.label} open={Boolean(open)} title={item.title}>
		// 			{renderNavItems({
		// 				depth: depth + 1,
		// 				pathname,
		// 				items: item.items,
		// 				permission,
		// 			})}
		// 		</NavItem>
		// 	);
		// }
	} else {
		// if (item.permission.includes(permission)) {
		if (permission != null) {
			if (item.permission.some((p) => permission.split(",").includes(p))) {
				acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} key={item.href} label={item.label} title={item.title} />);
			}
		}
	}

	return acc;
}

function NavBar({ openMobile, onMobileClose, className, ...rest }) {
	const classes = useStyles();
	const location = useLocation();
	const permission = useSelector((state) => state.auth.permission);

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}

		// eslint-disable-next-line
	}, [location.pathname]);

	const content = (
		<div {...rest} className={clsx(classes.root, className)}>
			<nav className={classes.navigation}>
				{navConfig.map((list) =>
					renderNavItems({
						items: list.items,
						subheader: list.subheader,
						pathname: location.pathname,
						permission,
						key: list.subheader,
					})
				)}
			</nav>
		</div>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{
						paper: classes.mobileDrawer,
					}}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{
						paper: classes.desktopDrawer,
					}}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
}

NavBar.propTypes = {
	className: PropTypes.string,
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

export default NavBar;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent, TextField, Grid, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from "@material-ui/core";
import axios from "../../../axios";

const useStyles = makeStyles((theme) => ({
	root: {},
	formGroup: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	cardContent: {
		paddingTop: theme.spacing(1),
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function Form({ isUpdate, hasError, formState, handleChange, handleCheckboxChange, className, ...rest }) {
	const classes = useStyles();
	const [permissions, setPermissions] = useState([]);

	useEffect(() => {
		axios
			.get("/api/permission")
			.then((response) => {
				console.log(response.data);
				setPermissions(response.data.permissions);
			})
			.catch((error) => console.log(error));
	}, []);

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardHeader title="" variant="outlined" />
			<CardContent className={classes.cardContent}>
				<form>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								size="small"
								autoFocus
								fullWidth
								label="Navn"
								name="name"
								error={hasError("name")}
								helperText={hasError("name") ? formState.errors.name[0] : null}
								value={formState.values.name || ""}
								onChange={handleChange}
								variant="outlined"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								size="small"
								fullWidth
								label="Email"
								name="email"
								error={hasError("email")}
								helperText={hasError("email") ? formState.errors.email[0] : null}
								value={formState.values.email || ""}
								onChange={handleChange}
								variant="outlined"
								type="email"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								size="small"
								fullWidth
								label="Telefon"
								name="phone"
								error={hasError("phone")}
								helperText={hasError("phone") ? formState.errors.phone[0] : null}
								value={formState.values.phone || ""}
								onChange={handleChange}
								variant="outlined"
								type="text"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								size="small"
								fullWidth
								label="Firma"
								name="company"
								error={hasError("company")}
								helperText={hasError("company") ? formState.errors.company[0] : null}
								value={formState.values.company || ""}
								onChange={handleChange}
								variant="outlined"
								type="text"
							/>
						</Grid>
					</Grid>
					<Grid container spacing={3} alignItems="center">
						<Checkbox color="primary" name="active" onChange={handleChange} checked={formState.values.active || false} />
						Aktiv?
					</Grid>
				</form>
			</CardContent>
		</Card>
	);
}

Form.propTypes = {
	className: PropTypes.string,
	hasError: PropTypes.func,
	formState: PropTypes.object,
	handleChange: PropTypes.func,
	handleCheckboxChange: PropTypes.func,
	isUpdate: PropTypes.bool,
};

export default Form;

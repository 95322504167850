/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "./layout/Dashboard";
import AuthLayout from "./layout/Auth";
import ErrorLayout from "./layout/Error";
import Home from "./components/Home/Home";
import InstallationCreate from "./views/Installation/Create";
import AllInstallations from "./views/Installation/AllInstallations";
import MyInstallations from "./views/Installation/MyInstallations";
import InstallationMy from "./views/Installation/MyInstallation";
import User from "./views/Settings/User";
import UserChangePassword from "./views/Settings/UserChangePassword";
import Users from "./views/Settings/Users";
import ElectrolysisUsers from "./views/Settings/ElectrolysisUsers";
import ElectrolysisUser from "./views/Settings/ElectrolysisUser";
import ElectrolysisRights from "./views/Settings/ElectrolysisRights";
import Hardness from "./views/Diverse/Hårdheder";
import AuthGuard from "./hoc/AuthGuard";

import RegisterSim from "./views/Sim/Register/index";
import MySim from "./views/Sim/MySim/index";
import MySimEdit from "./views/Sim/MySim/Edit/index";
import MySimActivated from "./views/Sim/MySim/Archive";
import AktiverSMS from "./views/Sim/Aktiver/SMS/index";
import AktiverElektrolyse from "./views/Sim/Aktiver/Elektrolyse/index";
import AktiverForhandler from "./views/Sim/Aktiver/Forhandler/index";
import SimReceived from "./views/Sim/Received";
import ElectrolysisInstallations from "./views/Electrolysis/Installations/index";
import ElectrolysisInstallation from "./views/Electrolysis/Installation/index";
import ElectrolysisOverview from "./views/Electrolysis/Overblik/index";
import ElectrolysisUpdate from "./views/Electrolysis/Update/index";

import LagerOptælling from "./views/Technician/ArticleCount";
import LagerOptællingRediger from "./views/Technician/ArticleCount.edit";
import LagerOptællingArkiv from "./views/Technician/ArticleCount.archive";
import LagerOptællingArkivAll from "./views/Technician/ArticleCount.archive.all";
import LagerOptællingArkivAllView from "./views/Technician/ArticleCount.archive.all.get";
import LagerOptællingHjaelp from "./views/Technician/ArticleCount.all.journals";

import ClientElectrolysisOverview from "./views/Client/Overview";
import ClientElectrolysisInstallations from "./views/Client/Installations";
import ClientElectrolysisInstallation from "./views/Client/Installation";
import ClientKontakt from "./views/Client/Kontakt";
import ClientFAQ from "./views/Client/FAQ";

export default [
	{
		path: "/",
		exact: true,
		component: () => <Redirect to="/overview" />,
	},
	{
		path: "/sim",
		component: DashboardLayout,
		routes: [
			{
				path: "/sim/mine",
				exact: true,
				component: MySim,
			},
			{
				path: "/sim/mine/:id",
				exact: true,
				component: MySimEdit,
			},
			{
				path: "/sim/aktiveret",
				exact: true,
				component: MySimActivated,
			},
			{
				path: "/sim/registrer",
				exact: true,
				component: RegisterSim,
			},
			{
				path: "/sim/aktiver/sms",
				exact: true,
				component: AktiverSMS,
			},
			{
				path: "/sim/aktiver/elektrolyse",
				exact: true,
				component: AktiverElektrolyse,
			},
			{
				path: "/sim/aktiver/forhandler",
				exact: true,
				component: AktiverForhandler,
			},
			{
				path: "/sim/modtaget",
				exact: true,
				component: SimReceived,
			},
		],
	},
	{
		path: "/elektrolyse",
		component: DashboardLayout,
		routes: [
			{
				path: "/elektrolyse/overblik",
				exact: true,
				component: ElectrolysisOverview,
			},
			{
				path: "/elektrolyse/installationer/:id",
				exact: true,
				component: ElectrolysisInstallation,
			},
			{
				path: "/elektrolyse/installationer",
				exact: true,
				component: ElectrolysisInstallations,
			},
			{
				path: "/elektrolyse/installationer/:id/opdater",
				exact: true,
				component: ElectrolysisUpdate,
			},
		],
	},
	{
		path: "/tekniker",
		component: DashboardLayout,
		routes: [
			{
				path: "/tekniker/lageroptaelling",
				exact: true,
				component: LagerOptælling,
			},
			{
				path: "/tekniker/lageroptaelling/arkiv",
				exact: true,
				component: LagerOptællingArkiv,
			},
			{
				path: "/tekniker/lageroptaelling/arkivall",
				exact: true,
				component: LagerOptællingArkivAll,
			},
			{
				path: "/tekniker/lageroptaelling/arkivall/:journalnumber",
				exact: true,
				component: LagerOptællingArkivAllView,
			},
			{
				path: "/tekniker/lageroptaelling/:journalnumber",
				exact: true,
				component: LagerOptællingRediger,
			},
			{
				path: "/tekniker/hjaelplageroptaelling",
				exact: true,
				component: LagerOptællingHjaelp,
			},
		],
	},
	{
		path: "/client",
		component: DashboardLayout,
		routes: [
			{
				path: "/client/overblik",
				exact: true,
				component: ClientElectrolysisOverview
			},
			{
				path: "/client/installationer",
				exact: true,
				component: ClientElectrolysisInstallations
			},
			{
				path: "/client/installation/:id",
				exact: true,
				component: ClientElectrolysisInstallation
			},
			{
				path: "/client/kontakt",
				exact: true,
				component: ClientKontakt
			},
			{
				path: "/client/faq",
				exact: true,
				component: ClientFAQ
			}
		]
	},
	{
		path: "/auth",
		component: AuthLayout,
		routes: [
			{
				path: "/auth/login",
				exact: true,
				component: lazy(() => import("./views/Auth/Login")),
			},
			{
				path: "/auth/register",
				exact: true,
				component: lazy(() => import("./views/Auth/Register")),
			},
			{
				path: "/auth/reset/:token",
				exact: true,
				component: lazy(() => import("./views/Auth/Reset")),
			},
			{
				path: "/auth/resetpassword",
				exact: true,
				component: lazy(() => import("./views/Auth/ResetPassword")),
			},
			{
				component: () => <Redirect to="/errors/error-404" />,
			},
		],
	},
	{
		path: "/errors",
		component: ErrorLayout,
		routes: [
			{
				path: "/errors/error-401",
				exact: true,
				component: lazy(() => import("./views/Error401")),
			},
			{
				path: "/errors/error-404",
				exact: true,
				component: lazy(() => import("./views/Error404")),
			},
			{
				path: "/errors/error-500",
				exact: true,
				component: lazy(() => import("./views/Error500")),
			},
			{
				component: () => <Redirect to="/errors/error-404" />,
			},
		],
	},
	{
		route: "*",
		component: DashboardLayout,
		routes: [
			{
				path: "/overview",
				exact: true,
				component: Home,
			},
			{
				path: "/installationer/opret",
				exact: true,
				component: InstallationCreate,
			},
			{
				path: "/installationer/opdater/:id",
				exact: true,
				component: InstallationCreate,
			},
			{
				path: "/installationer/alle",
				exact: true,
				component: AllInstallations,
			},
			{
				path: "/installationer/mine",
				exact: true,
				component: MyInstallations,
			},
			{
				path: "/installationer/mine/:id",
				exact: true,
				component: InstallationMy,
			},
			{
				path: "/administration/brugere/create",
				exact: true,
				component: AuthGuard(User),
			},
			{
				path: "/administration/brugere",
				exact: true,
				component: AuthGuard(Users),
			},
			{
				path: "/administration/brugere/:userid",
				exact: true,
				component: AuthGuard(User),
			},
			{
				path: "/administration/brugere/:userid/opdaterkode",
				exact: true,
				component: AuthGuard(UserChangePassword),
			},
			{
				path: "/administration/elektrolysebrugere",
				exact: true,
				component: AuthGuard(ElectrolysisUsers),
			},
			{
				path: "/administration/elektrolysebrugere/create",
				exact: true,
				component: AuthGuard(ElectrolysisUser),
			},
			{
				path: "/administration/elektrolysebrugere/:userid",
				exact: true,
				component: AuthGuard(ElectrolysisUser),
			},
			{
				path: "/administration/elektrolysebrugere/:userid/adgange",
				exact: true,
				component: AuthGuard(ElectrolysisRights),
			},
			{
				path: "/diverse/hårdheder",
				exact: true,
				component: Hardness,
			},
		],
	},
];

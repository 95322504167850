import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Page from "../../../components/Page";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	inputContent: {
		marginTop: theme.spacing(1.5),
	},
	button: {
		marginTop: theme.spacing(3),
	},
	doughnot: {
		maxWidth: "200px",
	},
	spacer: {
		margin: "30px 0",
	},
	mgTop: {
		marginTop: "10px",
	}
}));

const Contact = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
					<Grid item>
						<Typography component="h1" variant="h3">
							Kontakt BWT
						</Typography>
					</Grid>
				</Grid>

				<Grid className={classes.mgTop} container spacing={3}>
					<Grid item xs={12} md={6}>
						<Typography component="h3" variant="h5">
							Telefon
						</Typography>
						<Typography>
							<a href="tel:+4543600500">43 600 500</a>
						</Typography>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography component="h3" variant="h5">
							E-mail
						</Typography>
						<Typography>
							<a href="mailto:service@bwt.dk">service@bwt.dk</a>
						</Typography>
					</Grid>
				</Grid>

				<img className={classes.spacer} src="/banner_servicebesog.png" alt="Service" />
			
			</Container>
		</Page>
	);
};

export default Contact;

import { CircularProgress, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import Page from "../../../components/Page";
import Snackbar from "../../../components/Snackbar";
import BellAlertIcon from "../../../components/Icons/Bell-Alert";
import CheckCircle from "../../../components/Icons/Check-Circle";
import PauseCircle from "../../../components/Icons/Pause-Circle";
import SignalSlash from "../../../components/Icons/Signal-Slash";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	inputContent: {
		marginTop: theme.spacing(1.5),
	},
	button: {
		marginTop: theme.spacing(3),
	},
	doughnot: {
		maxWidth: "200px",
	},
	spacer: {
		margin: "30px 0",
	},
	mgTop: {
		marginTop: "10px",
	},
	mgTopTop: {
		marginTop: "10px",
		fontWeight: "bold",
	}
}));

const FAQ = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
					<Grid item>
						<Typography component="h1" variant="h3">
							Få tryghed med en serviceaftale 
						</Typography>
					</Grid>
				</Grid>

				<Typography className={classes.mgTop} variant="h5">
					Vi tilbyder et stort udvalg af serviceaftaler til vore kunder, alt fra en totaldækkende serviceaftale med døgnvagt og driftsovervågning til aftaler om service efter behov. Vi finder en løsning der passer til dit enkelte behov. 
				</Typography>

				<Typography className={classes.mgTop} variant="h5">
					Vi tilbyder service tilpasset til dine behov: 
				</Typography>
				<ul>
					<li>Serviceaftaler - filterskift, tilsyn samt reservedelsservice</li>
					<li>Opstart, idriftsættelse og uddannelse af lokalt driftspersonale</li>
					<li>Overvågning samt optimering af komplekse vandbehandlingsanlæg</li>
					<li>24-timers døgnvagt og support </li>
				</ul>
				<Typography className={classes.mgTop} variant="h5">
					For at yde dig den bedste service er vores service opdelt i 4 specialafdelinger:
				</Typography>
				<Typography className={classes.mgTopTop} variant="h5">
					Industri, erhverv og offentlig
				</Typography>
				<Typography variant="h5">
					Servicerer større anlæg på eksempelvis kraftværker, svømmehaller, bryggerier og øvrige produktionsvirksomheder. Her indgås også større servicekontrakter med stordriftsfordele for kommuner.
				</Typography>
				<Typography className={classes.mgTopTop} variant="h5">
					Større ejendomme, herunder elektrolyse 
				</Typography>
				<Typography variant="h5">
					Udfører primært service på korrosionsbeskyttelsesanlæg (elektrolyseanlæg) og vandbehandlingsanlæg i ejendomme til beskyttelse af det centrale varmeanlæg og kalkfrit vand i det varme brugsvand.
				</Typography>
				<Typography className={classes.mgTopTop} variant="h5">
					Hotel, restauration og vending branchen 
				</Typography>
				<Typography variant="h5">
					Vores serviceteknikere servicerer vandbehandlingsanlæg og udskifter filtre til opvaskemaskiner, kaffemaskiner, vendingmaskiner, isterningsmaskiner samt dampovne i kantiner, restaurationer, hoteller og cafeer.
				</Typography>
				<Typography className={classes.mgTopTop} variant="h5">
					Private kunder
				</Typography>
				<Typography variant="h5">
					Som privatkunde kan du registre dit anlæg på mit.bwt.dk. Her kan du booke en serviceteknik, hvis du skal have skiftet dine vandtilslutninger fra plastik til messing, og du kan få 2 års gratis tryghedsaftale med, når du registrer dit anlæg.   
				</Typography>

				<img className={classes.spacer} src="/banner_forside-service.jpg" alt="Service" />
			
			</Container>
		</Page>
	);
};

export default FAQ;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Typography, Grid, Button, CardContent, CircularProgress, Card, Checkbox, Switch } from "@material-ui/core";
import PropTypes from "prop-types";
import Page from "../../../components/Page";
import axios from "../../../axios";
import Snackbar from "../../../components/Snackbar";
import validate from "validate.js";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import SearchBar from "../../../components/SearchBar";
	
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Collapse from '@material-ui/core/Collapse';
import Person from '@material-ui/icons/Person';
import VideoLabel from '@material-ui/icons/VideoLabel';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	inputContent: {
		marginTop: theme.spacing(1.5),
	},
	accessContainer: {
		marginTop: theme.spacing(1.5),
	},
	button: {
		marginTop: theme.spacing(3),
	},
	formGroup: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(3),
	},
	cardContent: {
		paddingTop: theme.spacing(1),
	},
	nested: {
		paddingLeft: theme.spacing(8),
	},
	customerItem: {
		paddingLeft: "0px",
		borderTop: "1px solid rgb(192,192,192)",
		backgroundColor: "#f5f5f5",
	},
	bwtBlue: {
		color: "#00174B",
	}
}));

let schema = {
	password: {
		presence: { allowEmpty: false, message: "is required" },
	},
	confirmpassword: {
		presence: { allowEmpty: false, message: "is required" },
		equality: "password",
	},
};

const initialState = {
	isValid: false,
	values: {},
	touched: {},
	errors: {},
};

const UserUpdatePage = ({ match }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const { userid } = match.params;
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");
	const [formState, setFormState] = useState(initialState);
	const [searchString, setSearchString] = useState("");
	const location = useLocation();

	const [installations, setInstallations] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/electrolysis/installation/clientpermissions?searchstring=${searchString}&id=${userid}` )
			.then((response) => {
				console.log(response.data);
				const installations = response.data.installations;
				setInstallations(installations);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [])


	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));
	}, [formState.values, setFormState]);

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") return;
		setSnackbarMessage("");
		setSnackbarSeverity("");
		setSnackbarOpen(false);
	};

	const noUpdate = async () => {
		setLoading(true);
		
		// Step 1: Delete all the existing permissions

		// Step 2: Filter and clean the new permissions
		// Permissions is stored like this:
		// userId, customerNumber, installationNumbers
		// 225, 70971, * | user 225 has access to all installations for customer 70971
		// 225, 70971, 1 | user 225 has access to installation 1 for customer 70971
		// 225, 70971, 2,3 | user 225 has access to installation 2 and 3 for customer 70971

		// Step 3: Insert the new permissions

		const permissions = [];

		for (const installation of installations) {
			if (installation.open) {
				if (installation.permissionAll) {
					// User get access to all installations for this customer
					console.log(`${userid} gets access to all installations for customer ${installation.customerNumber}`);
					permissions.push({customerNumber: installation.customerNumber, installationNumbers: "*"});
				} else {
					let customerInstallations = installation.installations.filter((i) => i.open);
					let installationNumbers = customerInstallations.map((i) => i.installationNumber);

					console.log(`${userid} gets access to installations ${installationNumbers.join(",")} for customer ${installation.customerNumber}`);
					permissions.push({customerNumber: installation.customerNumber, installationNumbers: installationNumbers.join(",")});
				}
			}
		}

		console.log(permissions);

		try {
			const response = await axios.post("/api/electrolysis/installation/updateclientpermissions", {id: userid, permissions});
			console.log(response.data);
			setSnackbarMessage("Adgange opdateret");
			setSnackbarSeverity("success");
			setSnackbarOpen(true);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		let { search } = location;
		let _searchString = null;

		if (search) {
			search = search.substr(1);

			let searchJSON = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

			if ("searchstring" in searchJSON) {
				_searchString = searchJSON["searchstring"];
			}

			setSearchString(_searchString);

			axios.get(`/api/electrolysis/installation/clientpermissions?searchstring=${_searchString}&id=${userid}`).then((response) => {
				setInstallations(response.data.installations);
			});
		} else {
			axios.get(`/api/electrolysis/installation/clientpermissions?searchstring=${_searchString}&id=${userid}`).then((response) => {
				setInstallations(response.data.installations);
			});
		}
	}, []);

	const handleClickCustomerOpen = (index) => {
		// Create a new array by copying the existing installations array
		let _installations = [...installations];
		
		// Log the index and the specific installation object
		console.log(index);
		console.log(_installations[index]);
		
		// Update the specific installation object in the new array
		_installations[index] = {
			..._installations[index],
			open: !_installations[index].open
		};
		
		// Log the updated installations array
		console.log(_installations);
		
		// Update the state with the new array
		setInstallations(_installations);
	}

	const handleClickInstallationOpen = (customerIndex, installationIndex) => {
		let _installations = [...installations];

		console.log(_installations[customerIndex]);
		console.log(_installations[customerIndex].installations[installationIndex]);

		if(_installations[customerIndex].installations[installationIndex].installationNumber == 'Tillad adgang til alle installationer') {
			// set installations[customerIndex].permissionAll = !installations[customerIndex].permissionAll;
			_installations[customerIndex].permissionAll = !_installations[customerIndex].permissionAll;
		}

		_installations[customerIndex].installations[installationIndex] = {
			..._installations[customerIndex].installations[installationIndex],
			open: !_installations[customerIndex].installations[installationIndex].open
		};

		setInstallations(_installations);
	}

	const handleSearch = (event) => {
		event.preventDefault();

		axios.get(`/api/electrolysis/installation/clientpermissions?searchstring=${searchString}&id=${userid}`).then((response) => {
			setInstallations(response.data.installations);
		});
	}

	return (
		<Page className={classes.root}>
			<Container maxWidth="lg">
				<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
					<Grid item>
						<Typography component="h2" variant="overline">
							Bruger
						</Typography>
						<Typography component="h1" variant="h3">
							Tildel adgange
						</Typography>
					</Grid>
				</Grid>
				<Grid alignItems="flex-start" container spacing={3}>
					<Grid item>
						<form onSubmit={handleSearch}>
							<SearchBar searchString={searchString} setSearchString={setSearchString} onSearch={handleSearch} />
						</form>
					</Grid>
				</Grid>

				<Card className={classes.accessContainer}>
					<CardContent>
						<Typography component="h3" variant="h4" style={{ fontWeight: "bold" }}>
							Kunde adgang
						</Typography>
						<List component="nav" aria-labelledby="nested-list-subheader" >

							{installations.map((customer, customerIndex) => (
								<div key={customerIndex}>
									<ListItem className={classes.customerItem} button key={customer.customerNumber} onClick={() => handleClickCustomerOpen(customerIndex)}>
										<ListItemIcon className={classes.bwtBlue}>
											<Person />
										</ListItemIcon>
										<ListItemText className={classes.bwtBlue} primary={`Kunde ${customer.customerNumber}`} />
										<Switch className={classes.bwtBlue} checked={customer.open == true} />
									</ListItem>
									<Collapse in={customer.open} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											{customer.installations ? customer.installations.map((i, installationIndex) => (
												<ListItem button className={classes.nested} key={installationIndex}>
													<ListItemIcon className={classes.bwtBlue}>
														{i.installationNumber != 'Tillad adgang til alle installationer' ? (
															<VideoLabel />
														) : (<PlaylistAddCheckIcon />)}
													</ListItemIcon>
													<ListItemText className={classes.bwtBlue} primary={i.installationNumber != 'Tillad adgang til alle installationer' ? `Installation ${i.installationNumber}` : i.installationNumber} />
													<Switch disabled={customer.permissionAll === true && i.installationNumber != 'Tillad adgang til alle installationer'} checked={i.open == true} onClick={() => handleClickInstallationOpen(customerIndex, installationIndex)} />
												</ListItem>
											)): null}
										</List>
									</Collapse>
								</div>
							))}
						</List>
					</CardContent>
				</Card>

				<Card className={clsx(classes.button)}>
					<CardContent className={classes.cardContent}>
						<form>
							<Grid container spacing={10}>
								<Grid item xs>
									<div className={classes.formGroup} align="center">
										{loading ? (
											<CircularProgress />
										) : (
											<Button onClick={noUpdate} variant="contained" color="primary">
												Opdater adgange
											</Button>
										)}
									</div>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>
			</Container>
			{snackbarMessage && snackbarOpen && snackbarSeverity ? (
				<Snackbar open={snackbarOpen} autoHideDuration={6000} message={snackbarMessage} onClose={handleSnackbarClose} severity={snackbarSeverity} />
			) : null}
		</Page>
	);
};

UserUpdatePage.propTypes = {
	match: PropTypes.object.isRequired,
};

export default UserUpdatePage;

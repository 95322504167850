import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import Logo from "../../img/logo.png";

const useStyles = makeStyles(() => ({
	root: {
		boxShadow: "none",
		backgroundColor: "#00174B",
	},
	logo: {
		maxHeight: "40px",
		flexGrow: 1,
	},
	title: {
		flexGrow: 1,
	},
}));

function Topbar({ className, ...rest }) {
	const classes = useStyles();

	return (
		<AppBar {...rest} className={clsx(classes.root, className)} color="primary">
			<Toolbar>
				<Typography variant="h6" className={classes.title}>
					<img className={classes.logo} alt="Logo" src={Logo} />
				</Typography>
			</Toolbar>
		</AppBar>
	);
}

Topbar.propTypes = {
	className: PropTypes.string,
};

export default Topbar;
